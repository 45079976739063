var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.partisipants.length > 0 || (_vm.actions && _vm.actions.add_member))?_c('a-card',{staticClass:"lg:mt-base",class:_vm.isMobile ? 'member_card_mobile' : 'mt-6',attrs:{"title":!_vm.isMobile && _vm.$t('wgr.participants'),"size":"small"}},[(_vm.isMobile)?_c('h6',{staticClass:"font-semibold mb-1"},[_vm._v(" "+_vm._s(_vm.$t('wgr.participants'))+" ")]):_vm._e(),(_vm.isMobile && _vm.isStudent)?_c('a-button',{staticClass:"mb-4 mt-2 w-full flex items-center justify-center",attrs:{"loading":_vm.partisipantsLoading,"type":"ui"},on:{"click":function($event){_vm.visible = true}}},[_c('i',{staticClass:"fi fi-rr-user-add mr-2"}),_vm._v(" "+_vm._s(_vm.$t("wgr.invite_participants"))+" ")]):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.partisipantsLoading}},[_c('ul',{ref:"project_user_card",staticClass:"friend-suggesions-list"},_vm._l((_vm.partisipants),function(friend,index){return _c('li',{key:index,staticClass:"friend-suggestion flex items-center mb-4 justify-between"},[_c('Profiler',{attrs:{"nameClass":"text-sm","initStatus":"","getPopupContainer":_vm.getPopupContainer,"popoverText":friend.membership_role.code === 'FOUNDER' ? _vm.$t('wgr.director') : '' || friend.membership_role.code === 'MODERATOR' ? _vm.$t('wgr.moderator') : '',"subtitle":{ text: friend.membership_role.name, class: 'text-xs' },"user":friend.member}}),(_vm.actions && _vm.actions.add_member)?_c('div',{staticClass:"flex"},[(
                            _vm.isFounder &&
                                friend.membership_role.code !==
                                'FOUNDER'
                        )?_c('a-tooltip',{attrs:{"title":_vm.$t('wgr.remove_partisipant'),"placement":"left"}},[_c('a-button',{staticClass:"ml-1 cursor-pointer block ant-btn-icon-only text_red",attrs:{"loading":_vm.deleteLoading[friend.id] ? _vm.deleteLoading[friend.id] : false,"shape":"circle","ghost":"","type":"ui"},on:{"click":function($event){return _vm.deleteStudent(friend)}}},[_c('i',{staticClass:"fi fi-rr-remove-user"})])],1):_vm._e(),(
                            _vm.isFounder &&
                                friend.membership_role.code !==
                                'FOUNDER' &&
                                friend.membership_role.code !==
                                'MODERATOR'
                        )?_c('a-tooltip',{attrs:{"title":_vm.$t('wgr.change_moderator'),"placement":"bottom"}},[_c('div',{staticClass:"ml-2 cursor-pointer"},[_c('a-button',{staticClass:"ant-btn-icon-only",attrs:{"shape":"circle","loading":_vm.moderatorLoading[friend.id] ? _vm.moderatorLoading[friend.id] : false,"type":"ui","ghost":""},on:{"click":function($event){return _vm.toModerator(friend)}}},[_c('i',{staticClass:"fi fi-rr-following"})])],1)]):_vm._e()],1):_vm._e()],1)}),0)]),(_vm.actions && _vm.actions.add_member)?[(_vm.actions && _vm.actions.add_member)?[_c('UserDrawer',{attrs:{"id":"meetingCreate","multiple":"","buttonMode":"","buttonBlock":"","submitHandler":_vm.commitPartisipants,"buttonText":_vm.$t('wgr.invite_participants'),"changeMetadata":_vm.changeMetadata,"metadata":{ key: 'partisipants', value: _vm.form.metadata }},model:{value:(_vm.form.partisipants),callback:function ($$v) {_vm.$set(_vm.form, "partisipants", $$v)},expression:"form.partisipants"}})]:_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }